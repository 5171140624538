var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.response.status.id != '-2')?_c('v-card',{staticClass:"d-flex align-center text-center",class:[
      _vm.$vuetify.theme.dark ? 'border-dark' : 'border-light',
      _vm.getStatusColor(
        _vm.response.status.id,
        _vm.$vuetify.theme.dark ? 'dark' : 'light',
        'background'
      ),
    ],attrs:{"flat":"","height":"42px"},on:{"click":_vm.toggleResponseDetailDialog}},[_c('v-card-text',[_c('v-icon',{attrs:{"color":_vm.getStatusColor(
            _vm.response.status.id,
            _vm.$vuetify.theme.dark ? 'dark' : 'light',
            'text'
          )}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.response.status.id)))])],1),(_vm.response.comment)?_c('v-icon',{staticClass:"ml-n5",attrs:{"small":""}},[_vm._v("mdi-comment-text")]):_vm._e()],1):_vm._e(),(_vm.showResponseDetailDialog)?_c('CustomDialog',{attrs:{"title":"Rückmeldung","fullscreenOnMobile":"","width":"s","showCloseBtn":"","hideCloseBtn":"","hideActionBtn":""},on:{"close":function($event){return _vm.toggleResponseDetailDialog()}},scopedSlots:_vm._u([{key:"content",fn:function(){return undefined},proxy:true}],null,false,2459752288)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }