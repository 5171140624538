<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
      :persistent="persistent"
      scrollable
      :fullscreen="
        fullscreen || (fullscreenOnMobile ? $vuetify.breakpoint.xs : false)
      "
      :transition="
        fullscreen || (fullscreenOnMobile ? $vuetify.breakpoint.xs : false)
          ? 'dialog-bottom-transition'
          : ''
      "
    >
      <v-card
        v-if="appBarStyling"
        :color="$vuetify.theme.themes[theme].background"
      >
        <v-app-bar :color="$vuetify.theme.dark ? '' : 'white'" fixed>
          <v-app-bar-title dark>{{ title }}</v-app-bar-title
          ><slot name="titleChip"></slot>
          <v-chip
            color="teal lighten-5"
            v-if="isPreview"
            class="ml-4 font-weight-medium teal--text text--darken-5"
            ><v-icon left small>mdi-flask-empty</v-icon>Vorschau</v-chip
          >
          <v-spacer></v-spacer>
          <slot name="appBarButtons"></slot>
          <div v-if="showCloseBtn">
            <v-btn icon @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-app-bar>

        <v-card-text v-if="text">
          <div class="body-2" v-html="text"></div>
        </v-card-text>
        <v-card-text
          v-bind:style="manipulateHeightStyle()"
          style="padding-top: 94px !important"
        >
          <slot name="content"></slot>
        </v-card-text>
      </v-card>
      <v-card v-else :disabled="loading">
        <v-card-title>
          <span class="headline">{{ title }}</span
          ><slot name="titleChip"></slot>
          <v-chip
            color="teal lighten-5"
            v-if="isPreview"
            class="ml-4 font-weight-medium teal--text text--darken-5"
            ><v-icon left small>mdi-flask-empty</v-icon>Vorschau</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn v-if="showCloseBtn" icon @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle v-if="!subtitle">{{ subtitle }}</v-card-subtitle>
        <v-card-text v-if="text">
          <div class="body-2" v-html="text"></div>
        </v-card-text>
        <v-card-text v-bind:style="manipulateHeightStyle()">
          <slot name="content"> </slot>
        </v-card-text>
        <v-card-text v-if="errorMessage !== ''" class="mt-4">
          <v-alert dense text type="error" v-html="errorMessage"></v-alert>
        </v-card-text>
        <v-card-actions v-if="!hideActionBtn && !hideCloseBtn">
          <slot name="actions" v-if="!hideActionBtn && !hideCloseBtn">
            <v-btn v-if="!hideCloseBtn" text @click="$emit('close')"
              >Abbrechen</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="!hideActionBtn"
              :loading="loading"
              depressed
              :color="btnColor"
              :disabled="btnDisabled"
              @click="btnAction"
            >
              {{ btnText }}
              <v-icon v-if="btnIcon !== ''" right>{{ btnIcon }}</v-icon>
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "custom-dialog",
  props: {
    appBarStyling: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true,
    },
    btnText: {
      type: String,
      required: false,
      default: "Bestätigen",
    },
    btnColor: {
      type: String,
      required: false,
      default: "primary",
    },
    btnIcon: {
      type: String,
      required: false,
      default: "",
    },
    btnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnAction: {
      required: false,
    },
    hideActionBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCloseBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    snackbarModel: {
      type: Boolean,
      required: false,
      default: false,
    },
    snackbarMessage: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: String,
      required: false,
      default: "m",
    },
    height: {
      type: String,
      required: false,
      default: "m",
    },
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullscreenOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    maxWidth() {
      switch (this.width) {
        case "xs":
          return "500px";
        case "s":
          return "600px";
        case "m":
          return "800px";
        case "l":
          return "1000px";
        case "xl":
          return "1200px";
        case "xxl":
          return "1400px";
        default:
          return "800px";
      }
    },
    maxHeight() {
      switch (this.height) {
        case "auto":
          return "auto";
        case "xs":
          return "200px";
        case "s":
          return "400px";
        case "m":
          return "500px";
        case "l":
          return "600px";
        case "xl":
          return "700px";
        case "xxl":
          return "800px";
        case "xxxl":
          return "900px";
        default:
          return "500px";
      }
    },
  },
  methods: {
    manipulateHeightStyle() {
      if (this.maxHeight == "auto") {
        return "";
      } else {
        const style = "height: 500px" + this.maxHeight + ";";
        return style;
      }
    },
  },
};
</script>
