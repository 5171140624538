<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Frist erstellen"
      :actions="actions"
    ></NavigationBar>
    <error-message
      v-if="error.message"
      :message="error.message"
    ></error-message>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        @submit.prevent="createItem"
        ref="form"
      >
        <deadline-editor v-model="deadline" mode="new"></deadline-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ErrorMessage from "@/components/_systemwide/ErrorMessage.vue";
import DeadlineEditor from "@/components/member-management/deadlines/DeadlineEditor.vue";
import { MEMBER_MANAGEMENT_DEADLINES_create } from "@/data/permission-types.js";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  // GET_USER_LIST,
  CREATE_DEADLINE,
} from "@/store/action-types.js";

export default {
  name: "member-management-deadline-new",
  components: {
    NavigationBar,
    ErrorMessage,
    DeadlineEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${MEMBER_MANAGEMENT_DEADLINES_create}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      error: {
        message: "",
      },

      deadline: {
        title: "",
        shortTitle: "",
        sortKey: 0,
        description: "",
        applicableTo: {
          divisionIds: [],
          userGroupIds: [],
        },
        dueType: "day",
        watchers: [
          {
            title: "Gültig",
            color: "#4CAF50",
            operator: ">",
            comparisonValue: "0",
          },
          {
            title: "Nächste 30 Tage",
            color: "#FF9800",
            operator: "<",
            comparisonValue: "30",
          },
          {
            title: "Abgelaufen",
            color: "#F44336",
            operator: ">",
            comparisonValue: "-1",
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},

    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_DEADLINE}`, {
            organizationId: this.$route.params.organizationId,
            title: this.deadline.title,
            shortTitle: this.deadline.shortTitle,
            sortKey: this.deadline.sortKey,
            description: this.deadline.description,
            applicableTo: {
              divisionIds: this.deadline.applicableTo.divisionIds,
              userGroupIds: this.deadline.applicableTo.userGroupIds,
            },
            dueType: this.deadline.dueType,
            watchers: this.deadline.watchers,
          })
          // .then(() => {
          //   return this.$router.push({
          //     name: "member-management-deadline-monitoring",
          //     params: {
          //       organizationId: this.$route.params.organizationId,
          //     },
          //   });
          // })
          .catch(function (error) {
            this.error.message = error.message;
            console.error(error);
          });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
