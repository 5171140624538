<template>
  <div>
    <v-card
      outlined
      height="100%"
      :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'"
      class="d-block align-center text-truncate"
      @click="$emit('toggle')"
    >
      <v-list-item dense class="px-2">
        <v-icon class=""
          >{{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
        </v-icon>
        <span class="font-weight-medium text-truncate">{{ title }}</span>
        <small class="grey--text ml-1">({{ groupCount }})</small>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "teamplanner-group-header-card",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    groupCount: {
      required: true,
    },
  },
  methods: {},
};
</script>
