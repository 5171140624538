<template>
  <div>
    <v-card outlined @click="toggleEventDetailsDialog" height="100%">
      <v-card-title
        class="font-weight-medium text-left subtitle-2 pt-1 pb-0 px-2 d-block text-truncate"
      >
        {{ deadline.shortTitle }}
      </v-card-title>

      <v-card-text class="text-left px-2 pb-1">
        <br />
        <!-- <div class="text-truncate">
          <v-icon x-small left class="mr-1">mdi-map-marker</v-icon
          ><small>{{ deadline }}</small>
        </div> -->
      </v-card-text>
    </v-card>
    <CustomDialog
      v-if="showEventDetailsDialog"
      @close="toggleEventDetailsDialog()"
      title="Details"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content>
        <br />
        <!-- <event-details-card
          :event="event"
          showDetailsButton
          outlined
        ></event-details-card> -->
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
// import EventDetailsCard from "@/components/scheduling/EventDetailsCard.vue";
export default {
  name: "matrix-deadline-card",
  components: {
    CustomDialog,
    // EventDetailsCard
  },
  props: {
    deadline: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEventDetailsDialog: false,
    };
  },
  methods: {
    toggleEventDetailsDialog() {
      this.showEventDetailsDialog = !this.showEventDetailsDialog;
    },
  },
};
</script>
