<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col>
          <v-alert
            type="error"
            border="left"
            colored-border
            elevation="2"
            class="font-weight-medium"
            >{{ message }}</v-alert
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "error-message",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
