<template>
  <div>
    <v-card outlined>
      <v-card-text class="text-left px-2 py-1">
        <v-icon small left class="mr-1">mdi-check-circle</v-icon
        ><small>{{ count.totalConfirmations }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-minus-circle</v-icon
        ><small>{{ count.totalIfNecessary }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-close-circle</v-icon
        ><small>{{ count.totalCancellations }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-airplane</v-icon
        ><small>{{ count.totalNotInTown }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-help-circle</v-icon
        ><small>{{ count.totalAwaitingResponse }}</small>
        <br />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-left px-2 py-1">
        <v-icon small left class="mr-1">mdi-sigma</v-icon
        ><small>{{ count.totalResponses }}</small>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "teamplanner-responses-summary-card",
  components: {},
  props: {
    count: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
