<template>
  <div>
    <v-card
      v-if="response.status.id != '-2'"
      flat
      height="42px"
      class="d-flex align-center text-center"
      :class="[
        $vuetify.theme.dark ? 'border-dark' : 'border-light',
        getStatusColor(
          response.status.id,
          $vuetify.theme.dark ? 'dark' : 'light',
          'background'
        ),
      ]"
      @click="toggleResponseDetailDialog"
    >
      <v-card-text>
        <v-icon
          :color="
            getStatusColor(
              response.status.id,
              $vuetify.theme.dark ? 'dark' : 'light',
              'text'
            )
          "
          >{{ getStatusIcon(response.status.id) }}</v-icon
        >
      </v-card-text>
      <v-icon v-if="response.comment" small class="ml-n5"
        >mdi-comment-text</v-icon
      >
      <!-- <v-icon v-if="response.isAccepted" small class="ml-n5">mdi-star</v-icon>
      <v-icon v-if="response.guests.count !== 0" small class="ml-n5"
        >mdi-account-group</v-icon
      >

      <v-icon v-if="response.isAccepted" small class="ml-n5"
        >mdi-clipboard-list</v-icon
      >
      <v-icon v-if="response.isAccepted" small class="ml-n5"
        >mdi-message-alert</v-icon
      > -->
    </v-card>
    <CustomDialog
      v-if="showResponseDetailDialog"
      @close="toggleResponseDetailDialog()"
      title="Rückmeldung"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content> </template>
    </CustomDialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
export default {
  name: "teamplanner-responnse-status-card",
  components: { CustomDialog },
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResponseDetailDialog: false,
    };
  },
  methods: {
    toggleResponseDetailDialog() {
      this.showResponseDetailDialog = !this.showResponseDetailDialog;
    },

    getStatusColor(status, theme = "light", colorScheme = "background") {
      if (colorScheme === "background") {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple lighten-4" : "purple lighten-4";
          case "0":
          case "Unknown":
            return "blue-grey lighten-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green lighten-4" : "green lighten-4";
          case "2":
          case "no":
            return theme === "dark" ? "red lighten-4" : "red lighten-4";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange lighten-4" : "orange lighten-4";
          case "4":
          case "away":
            return theme === "dark" ? "blue lighten-4" : "blue lighten-4";
          case "":
          default:
            return "blue-grey lighten-2";
        }
      } else {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple darken-2" : "purple darken-2";
          case "0":
          case "Unknown":
            return "blue-grey darken-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green darken-2" : "green darken-2";
          case "2":
          case "no":
            return theme === "dark" ? "red darken-2" : "red darken-2";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange darken-2" : "orange darken-2";
          case "4":
          case "away":
            return theme === "dark" ? "blue darken-2" : "blue darken-2";
          case "":
          default:
            return "blue-grey darken-2";
        }
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "-1":
          return "mdi-cancel";
        case "0":
        case "Unknown":
          return "mdi-help-circle";
        case "1":
        case "yes":
          return "mdi-check-circle";
        case "2":
        case "no":
          return "mdi-close-circle";
        case "3":
        case "maybe":
          return "mdi-minus-circle";
        case "4":
        case "away":
          return "mdi-airplane";
        case "":
        default:
          return "mdi-alert-outline";
      }
    },
  },
};
</script>

<style>
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}
</style>
