<template>
  <div>
    <section v-if="!helpers.initialLoading" class="mt-2 mx-2">
      <v-card v-if="deadlines.length" height="100%">
        <div class="table-container">
          <table class="table-layout">
            <thead>
              <tr>
                <th class="team-header rounded transparent"></th>
                <th
                  v-for="deadline in deadlines"
                  :key="deadline.meta.id"
                  class="event-header rounded ELEV"
                >
                  <matrix-deadline-card
                    :deadline="deadline"
                  ></matrix-deadline-card>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- units -->
              <!-- tasks -->

              <!-- responses for each user -->

              <!-- responses -->
              <template v-for="userGroup in rowGroups">
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header" :key="userGroup.id">
                  <td class="role-name rounded ELEV">
                    <matrix-group-header-card
                      :title="userGroup.title"
                      :isOpen="isGroupOpen(userGroup.id)"
                      :groupCount="sortedTeamMembers(userGroup).length"
                      @toggle="toggleGroup(userGroup.id)"
                    >
                    </matrix-group-header-card>
                  </td>
                </tr>

                <!-- responses for each user -->
                <template v-if="isGroupOpen(userGroup.id)">
                  <!-- user details -->
                  <tr
                    v-for="user in sortedTeamMembers(userGroup)"
                    :key="user.user.uid"
                    class="member-row"
                  >
                    <td class="member-name rounded ELEV">
                      <matrix-person-card :user="user"></matrix-person-card>
                    </td>

                    <!-- response for each event -->
                    <td
                      v-for="deadline in deadlines"
                      :key="deadline.id"
                      class="response-status rounded ELEV"
                    >
                      <matrix-deadline-status-card
                        :deadlineStatus="getDeadlineStatus(deadline, user)"
                      ></matrix-deadline-status-card>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <tfoot>
              <!-- responses summary -->
              <tr>
                <td
                  class="sum-name rounded ELEV"
                  :class="
                    $vuetify.theme.dark
                      ? ['grey darken-2', 'border-dark']
                      : ['grey lighten-3', 'border-light']
                  "
                >
                  <v-card
                    flat
                    height="100%"
                    class="d-block align-center text-truncate transparent"
                  >
                    <v-list-item dense class="px-2">
                      <v-icon class="ml-2">mdi-sigma </v-icon>
                      <span class="font-weight-medium ml-2 text-truncate"
                        >Summe</span
                      >
                    </v-list-item>
                  </v-card>
                </td>

                <!-- responses summary for each event -->
                <td
                  v-for="deadline in deadlines"
                  :key="deadline.meta.id"
                  class="response-status rounded ELEV"
                >
                  <matrix-summary-card
                    :count="getSummaryCount(deadlines)"
                  ></matrix-summary-card>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="d-flex justify-center align-center">
          <span class="font-weight-medium"
            >Keine Fristen zur Überwachung vorhanden</span
          >
        </v-card-text>
      </v-card>
    </section>
    <Loading v-else overlay></Loading>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
// import { db, Timestamp } from "@/firebase";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_USER_LIST, GET_DEADLINES } from "@/store/action-types.js";
import Loading from "@/components/_system/helpers/Loading.vue";
import MatrixDeadlineCard from "@/components/member-management/deadlines/MatrixDeadlineCard.vue";
import MatrixPersonCard from "@/components/member-management/deadlines/MatrixPersonCard.vue";
import MatrixSummaryCard from "@/components/member-management/deadlines/MatrixSummaryCard.vue";
import MatrixDeadlineStatusCard from "@/components/member-management/deadlines/MatrixDeadlineStatusCard.vue";
import MatrixGroupHeaderCard from "@/components/member-management/deadlines/MatrixGroupHeaderCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";

export default {
  name: "member-management-deadline-monitoring-tab-matrix",
  components: {
    MatrixDeadlineCard,
    MatrixPersonCard,
    MatrixDeadlineStatusCard,
    MatrixSummaryCard,
    MatrixGroupHeaderCard,
    Loading,
    SupportTools,
  },
  data() {
    return {
      helpers: {
        initialLoading: true,
      },

      groupOpenStatus: {},
    };
  },
  computed: {
    deadlines() {
      return this.$store.state.memberManagement.deadlines;
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    // membershipsPrepared() {
    //   var range = {
    //     start: this.event.duration.start.timestampToDate,
    //     end: this.event.duration.end.timestampToDate,
    //   };
    //   var membershipsFiltered = this.memberships.filter((obj) => {
    //     return (
    //       new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this event
    //       !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the event of this event
    //       obj.access.type === "member"
    //     );
    //   });

    //   var memberships = membershipsFiltered;
    //   // if (this.event.status !== "planned") {}
    //   const result = memberships.map((item) => {
    //     const container = {
    //       groupByGroup:
    //         item.group.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.group.title,
    //       groupByDivision:
    //         item.user.division.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.user.division.title,
    //       group: {
    //         title: item.group.title,
    //         sortKey: item.group.sortKey,
    //         id: item.group.id,
    //       },
    //       user: {
    //         displayName: item.user.displayName,
    //         uid: item.user.uid,
    //         // photoURL = item.user.photoURL,
    //         // position = item.user.dutyPosition,
    //       },
    //     };
    //     return container;
    //   });
    //   return result;
    // },
    rowGroups() {
      const uniqueGroups = [];
      const groupIds = new Set();

      this.memberships.forEach((member) => {
        if (!groupIds.has(member.group.id)) {
          groupIds.add(member.group.id);
          uniqueGroups.push(member.group);
        }
      });

      return uniqueGroups.sort((a, b) => a.sortKey - b.sortKey);
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "deadlines", data: this.deadlines },
        { title: "memberships", data: this.memberships },
        { title: "rowGroups", data: this.rowGroups },
        { title: "groupOpenStatus", data: this.groupOpenStatus },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      await this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DEADLINES}`, {
        organizationId: this.$route.params.organizationId,
      });

      for (const userGroup of this.rowGroups) {
        this.groupOpenStatus[userGroup.id] = true;
      }

      setTimeout(() => {
        this.helpers.initialLoading = false;
      }, 1);
    },

    toggleGroup(userGroupId) {
      this.groupOpenStatus = {
        ...this.groupOpenStatus,
        [userGroupId]: !this.groupOpenStatus[userGroupId],
      };
    },
    isGroupOpen(userGroupId) {
      return this.groupOpenStatus[userGroupId];
    },
    sortedTeamMembers(userGroup) {
      return this.memberships
        .filter((member) => member.group.id === userGroup.id)
        .sort((a, b) => a.user.displayName.localeCompare(b.user.displaName));
    },

    getDeadlineStatus(deadline, user) {
      const emptyDeadlinesStatus = {
        meta: {
          uid: user.user.uid,
          lastUpdatedBy: "",
        },
        status: {
          id: "0",
        },
      };

      // const response = deadline.responses.find(
      //   (response) => response.meta.uid === user.user.uid
      // );

      // if (!response) {
      //   emptyDeadlinesStatus.status.id = "-1";
      // }

      // return response ? response : emptyDeadlinesStatus;
      return emptyDeadlinesStatus;
    },
    getSummaryCount() {
      // const possibleUsers = this.memberships.filter((user) => {
      //   return (
      //     this.isUserInvited(event, user) && this.isUserActive(event, user)
      //   );
      // });
      // const possibleUsersWithoutResponses = possibleUsers.filter((user) => {
      //   return !event.responses.find(
      //     (response) => response.meta.uid === user.user.uid
      //   );
      // });
      // return {
      //   totalResponses: event.responses.length,
      //   totalConfirmations: event.responses.filter(
      //     (response) => response.status.id === "1"
      //   ).length,
      //   totalCancellations: event.responses.filter(
      //     (response) => response.status.id === "2"
      //   ).length,
      //   totalIfNecessary: event.responses.filter(
      //     (response) => response.status.id === "3"
      //   ).length,
      //   totalNotInTown: event.responses.filter(
      //     (response) => response.status.id === "4"
      //   ).length,
      //   totalAwaitingResponse: possibleUsersWithoutResponses.length,
      // };
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 6px;
  border-collapse: separate;
}
table > thead > tr > th {
  position: sticky;
  top: 0;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-spacing: 0;
}

tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
}

.crossed::after,
.crossed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.crossed::after {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}

.crossed::before {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.table-container {
  overflow-x: auto;
  display: flex;
}

.table-layout {
  table-layout: fixed;
  min-width: max-content;
}

.team-header {
  width: 225px;
  max-width: 190px !important;
  max-height: 100px;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
}

.event-header {
  width: 140px;
  max-width: 140px !important;
  position: sticky !important;
  top: 0;
}

.role-header {
  height: 30px;
}

.role-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
}

.sum-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
  z-index: 2;
}

.role-colspan {
  border-left: none;
}

.task-row {
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
}

.task-cell {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2;
}

.member-row {
  /* min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important; */
}

.member-name {
  /* position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2; */
}

.response-status {
}
</style>
