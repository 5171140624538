<template>
  <div>
    <NavigationBar
      titel="Fristenüberwachung"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "member-management-deadline-monitoring",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `general`,
      tabs: [
        { id: 1, name: "Übersicht", route: `overview` },
        { id: 2, name: "Matrix", route: `matrix` },
        { id: 3, name: "Tauglichkeiten", route: `physicals` },
      ],
    };
  },
  computed: {},
};
</script>
