<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="3" sm="4">
                        <v-text-field
                          v-model="deadlineLocal.shortTitle"
                          label="Kurzbezeichnung"
                          outlined
                          required
                          counter
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="8">
                        <v-text-field
                          v-model="deadlineLocal.title"
                          label="Bezeichnung"
                          required
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="4">
                        <v-text-field
                          v-model.number="deadlineLocal.sortKey"
                          label="Sortierschlüssel"
                          hint="Fristen befolgen eine Rangfolge und werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          type="number"
                          persistent-hint
                          required
                          outlined
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="deadlineLocal.description"
                          hide-details="auto"
                          clearable
                          no-resize
                          rows="3"
                          label="Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-autorenew"
              title="Fristenberechnung"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <v-select
                          v-model="deadlineLocal.dueType"
                          :items="deadlineTypeValues"
                          label="Fristenablauf"
                          outlined
                          hide-details="auto"
                          required
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        Anwendbar auf
                        <v-select
                          v-model="deadlineLocal.applicableTo.divisionIds"
                          label="Abteilung"
                          outlined
                          hide-details="auto"
                          required
                          multiple
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        Anwendbar auf
                        <v-select
                          v-model="deadlineLocal.applicableTo.userGroupIds"
                          label="Benutzergruppe"
                          disabled
                          outlined
                          multiple
                          hide-details="auto"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12">
            <Subheader icon="mdi-stairs-box" title="Schwellen"></Subheader>
          </v-col>
          <v-col
            v-for="(watcher, index) in deadlineLocal.watchers"
            :key="index"
            cols="12"
          >
            <v-card>
              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3">
                  <v-card-text>
                    Vorschau
                    <v-card
                      class="mt-2 pa-2"
                      :color="`${getMaterialColor(watcher.color)} lighten-4`"
                      dark
                      outlined
                    >
                      <div
                        :class="[
                          'font-weight-medium text-h5 text-center text--darken-4',
                          `${getMaterialColor(watcher.color)}--text`,
                        ]"
                      >
                        12
                      </div>
                      <div
                        :class="[
                          'font-weight-medium subtitle-2 text-center text--darken-4',
                          `${getMaterialColor(watcher.color)}--text`,
                        ]"
                      >
                        {{ watcher.title }}
                      </div>
                    </v-card>
                  </v-card-text>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4" sm="4">
                          <v-text-field
                            v-model.trim="watcher.title"
                            label="Bezeichnung"
                            outlined
                            required
                            hide-details="auto"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="4">
                          <v-select
                            v-model="watcher.operator"
                            :items="watcherOperatorValues"
                            label="Fristenablauf"
                            outlined
                            hide-details="auto"
                            required
                            :rules="[rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" sm="4">
                          <v-text-field
                            v-model.number="watcher.comparisonValue"
                            type="number"
                            label="Fristablauf in Tagen"
                            outlined
                            required
                            hide-details="auto"
                            :rules="[rules.number]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          Farbe wählen
                          <v-color-picker
                            v-model="watcher.color"
                            :swatches="availableColors"
                            hide-inputs
                            hide-canvas
                            hide-sliders
                            show-swatches
                            width="1200"
                            hide-details="auto"
                            swatches-max-height="150"
                          ></v-color-picker>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  class="hidden-md-and-down"
                  @click="deleteWatcher(index)"
                >
                  <v-icon left>mdi-delete</v-icon>Entfernen
                </v-btn>
                <v-btn
                  color="error"
                  icon
                  text
                  class="hidden-lg-and-up"
                  @click="deleteWatcher(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn color="success" @click="addWatcher()">
              <v-icon left>mdi-plus</v-icon>Schwelle hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "deadline-editor",
  props: {
    deadline: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        number: (value) =>
          /^-?[0-9]\d*(\.\d+)?$/.test(value) ||
          "Erforderlich: Wert muss eine Zahl sein.",
      },

      deadlineTypeValues: [
        { text: "Taggenaue Fälligkeit", value: "day" },
        { text: "Fälligkeit zum Monatsende", value: "month-end" },
        { text: "Fälligkeit zum Quartalsende", value: "quarter-end" },
        { text: "Fälligkeit zum Halbjahresende", value: "halfyear-end" },
        { text: "Fälligkeit zum Jahresende", value: "year-end" },
      ],
      watcherOperatorValues: [
        { text: "Weniger als", value: "<" },
        { text: "Größer als", value: ">" },
      ],
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
    };
  },
  model: {
    prop: "deadline",
    event: "deadlineChange",
  },
  computed: {
    deadlineLocal: {
      get: function () {
        return this.deadline;
      },
      set: function (value) {
        this.$emit("deadlineChange", value);
      },
    },
    memberships() {
      var result = this.$store.state.admin.membershipsPublic.map((item) => {
        return {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
      });
      return result;
    },
  },
  methods: {
    addWatcher() {
      this.deadlineLocal.watchers.push({
        title: "",
        color: "#F44336",
        value: 0,
      });
    },
    deleteWatcher(index) {
      this.deadlineLocal.watchers.splice(index, 1);
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
        case "red":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
        case "pink":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
        case "purple":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
        case "deep-purple":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
        case "indigo":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
        case "blue":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
        case "light-blue":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
        case "cyan":
          return "cyan";
        case "#009688FF":
        case "#009688":
        case "teal":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
        case "green":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
        case "light-green":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
        case "lime":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
        case "yellow":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
        case "amber":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
        case "orange":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
        case "deep-orange":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
        case "brown":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
        case "blue-grey":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
        case "grey":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
