var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"to":{
      name: 'member-management-deadline-monitoring-deadline-details',
      params: {
        itemId: _vm.deadline.meta.id,
      },
    }}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[(!_vm.hideTitle)?_c('v-card-title',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.deadline.title)+" ")]):_vm._e(),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.deadline.watchers),function(watcher,index){return _c('v-col',{key:index,attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":`${_vm.getMaterialColor(watcher.color)} lighten-4`,"dark":"","disabled":watcher.value == 0,"outlined":""}},[_c('div',{class:[
                    'font-weight-medium text-h5 text-center text--darken-4',
                    `${_vm.getMaterialColor(watcher.color)}--text`,
                  ]},[_vm._v(" "+_vm._s(watcher.value)+" ")]),_c('div',{class:[
                    'font-weight-medium subtitle-2 text-center text--darken-4',
                    `${_vm.getMaterialColor(watcher.color)}--text`,
                  ]},[_vm._v(" "+_vm._s(watcher.title)+" ")])])],1)}),1)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"3"}},[_c('VueApexCharts',{staticClass:"mx-2 px-2",attrs:{"type":"donut","options":_vm.timeSpendingChartConfig,"series":_vm.timeSpendingChartSeries}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }