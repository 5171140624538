import { render, staticRenderFns } from "./DeadlineMonitoringTabMatrix.vue?vue&type=template&id=fb6221ec&scoped=true"
import script from "./DeadlineMonitoringTabMatrix.vue?vue&type=script&lang=js"
export * from "./DeadlineMonitoringTabMatrix.vue?vue&type=script&lang=js"
import style0 from "./DeadlineMonitoringTabMatrix.vue?vue&type=style&index=0&id=fb6221ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6221ec",
  null
  
)

export default component.exports